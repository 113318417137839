import React from 'react';

import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import Layout from '@/components/Layout';
import { privacy } from '@/content/index';

const Privacy = () => (
  <Layout className="layout__background">
    <Header />
    <div className="privacy__container">
      <div dangerouslySetInnerHTML={{ __html: privacy }} />
    </div>
    <Footer />
  </Layout>
);

export default Privacy;
