import { Link } from 'gatsby';
import React from 'react';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="footer">
      <div className="footer__inner">
        <div className="footer__links">
          <Link to="/imprint">Imprint</Link> |{' '}
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
        <div className="footer__copyright">
          <p>&copy; {year}. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
